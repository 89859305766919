.nav-item {
    color: #000;
    gap: 15px;
}

.nav-item-address {

    color: #0d7ff0;

}

.header-layout {
    background-color: #0d7ff0;
    padding-bottom: 40px;
}

.header-tel {
    color: #0d7ff0;
    text-decoration: none;
    font-size: 15px;
}

.container-header-layout {
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    background-color: #fff;
    padding: 14px 48px;
}

.container-header__text {
    gap: 20px;
    background-color: transparent;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    color: #fff;
}

.container-header__text > button {
    border-radius: 50px;
    background-color: #291477;
    border: 0;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}

.container-header__text > h1 {
    max-width: 800px;
    line-height: 1.6;
    text-align: center;
    font-weight: 700;
}

.container-header__text > p {
    text-align: center;
}

.header-icons > a > img {
    width: 32px;
    height: auto;
    border: solid 1px #000000bd;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    transition: border 0.2s ease-in-out;
}

.header-icons > a > img:hover {
    border: solid 1px #ffc107 !important;
    box-shadow: #ffc107 0 0 5px;
}

.list-group-item {
    padding: 0 !important;
    border: 0 !important;
}

@media (max-width: 768px) {
    .header-navbar {
        font-size: 14px;
    }
}

@media (max-width: 425px) {
    .header-icons > a > img {
        width: 24px !important;
        height: auto;
    }
    /* .nav-item-address {
        max-width: 195px;
    } */
}

@media (max-width: 375px) {
    .header-tel, .nav-item-address {
        font-size: 11px;
    }
}