.about-layout {
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
}

.container-about>h2 {
    text-transform: uppercase;
    color: white;
    font-size: 1rem;
    line-height: 1.15;
    letter-spacing: 1.2;
    font-weight: 700;
}

.container-about>h3 {
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 1.6;
    max-width: 500px;
    margin: 0 auto;
    word-wrap: break-word;
}

.container-about>p {
    font-weight: 400;
    font-size: 18px;
    line-height: 1.6;
    color: #595959;
    margin: 0 auto;
    max-width: 500px;
    word-wrap: break-word;
}

.aboutlogo-image {
    object-fit: cover;
    background-size: cover;
    width: 400px;
    height: 400px;
    background-position: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.features {
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #EFF0F2;
}

.container-features>h2 {
    text-transform: uppercase;
    color: #0d7ff0;
    font-size: 1.5rem;
    line-height: 1.15;
    letter-spacing: 1.2;
    font-weight: 700;
}

.prices > .container-features > h2 {
    color: #fff;
}

.container-features>h3 {
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 1.6;
    max-width: 500px;
    margin: 0 auto;
    word-wrap: break-word;
}

.gallery {
    background-color: #fff;
}

.prices {
    margin-top: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #0d7ff0;
}

.prices h4,
.prices p {
  color: #fff;
}

.container-footer-layout {
    color: #EFF0F2 !important;
}

.span-bold {
    font-weight: bold;
}

@media (max-width: 768px) {
    .about-layout {
        flex-direction: column !important;
    }
    .container-about-image>img {
        width: calc(20px + (100 * 15 * 0.7) * (100vw / 1320));
        height: calc(20px + (100 * 15 * 0.7) * (100vw / 1320));
        transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
        -webkit-transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
        -moz-transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
        -ms-transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
        -o-transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
}

    .container-about>h3 {
        font-size: calc(1.5rem + (40 + 40 * 0.7) * ((100vw - 320px) / 1320));
        margin-bottom: 15px;
    }
    .container-about>p {
        margin-bottom: 15px;
    }
    .faq {
        flex-direction: column;
    }
    .container-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }
    .nav-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 425px) {
    .feature-icon {
        margin: 0 auto;
        width: auto;
    }
}