
.gallery-image {
    object-fit: cover;
    width: 300px;
    height: 300px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    transition: box-shadow 0.3s ease-in-out;
    -webkit-transition: box-shadow 0.3s ease-in-out;
    -moz-transition: box-shadow 0.3s ease-in-out;
    -ms-transition: box-shadow 0.3s ease-in-out;
    -o-transition: box-shadow 0.3s ease-in-out;
    cursor: pointer;
}

.gallery-image:hover {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
}

.gallery-image-badge {
    left: 25px;
    top: 10px;
}

@media (max-width: 768px) {
    .gallery-image {
        width: 100vw;
    }
    .modal-dialog {
        margin: 0;
        max-width: 100vw;
    }
    .img-modal {
        width: 100%;
    }
}