.footer {
    background-color: #0d7ff0;
}

.container-footer-layout {
    color: #EFF0F2 !important;
}

.footer > .container-features > h2 {
    color: #fff;
}

.footer-accordion {
    --bs-accordion-btn-bg: #005dbb;
    --bs-accordion-btn-color: #EFF0F2;
    --bs-accordion-active-color: #EFF0F2;
    --bs-accordion-active-bg: #005dbb;
    --bs-accordion-border-color: #00478d;
}

@media (max-width: 768px) {
    .container-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }
}