.feature-card {
    background-color: #fff;
    padding: 2px 5px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    transition: box-shadow 0.3s ease-in-out;
    -webkit-transition: box-shadow 0.3s ease-in-out;
    -moz-transition: box-shadow 0.3s ease-in-out;
    -ms-transition: box-shadow 0.3s ease-in-out;
    -o-transition: box-shadow 0.3s ease-in-out;
}

.feature-card:hover {
    box-shadow: 0 2px 5px rgba(0,0,0,0.5);
}